'use client';

import { Fragment } from 'react';

import {
  SubscriptionCard,
  SubscriptionCardProps,
  SubscriptionSkeletonCard,
} from './SubscriptionCard';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import { ClassNameProps } from '@/types/common.types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useEffectOnceWhen } from '@/hooks/useEffectOnceWhen';

import { TransformedSubscription } from '@/api/types/user.types';

import { SubscriptionsIds } from '@/enums/profile-subscriptions-paths.enums';

import { ScrollToItemSlider } from '@/components/Slider/ScrollToItemSlider';

import {
  ResultSubscriptionInfo,
  SelectedSubscriptionInfo,
} from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SelectedSubscriptionInfo';
import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SubscriptionRow.module.scss';
import { useSubscriptionVerification } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts';

// =================================================================

interface SubscriptionRowProps extends ClassNameProps {
  subscriptions: TransformedSubscription[];
  title?: string;
  loadingSubscription: boolean;
}

// =================================================================

export const SubscriptionRow = (props: SubscriptionRowProps) => {
  const { subscriptions, loadingSubscription, className } = props;

  const { setSubscription, subscription: selectedSubscription } = useVerification();

  const { paymentStatus } = useSubscriptionVerification();

  const handlePurchaseStart = (subscription: TransformedSubscription) => {
    setSubscription(subscription);
  };

  useEffectOnceWhen(() => {
    setSubscription(subscriptions[0]);
  }, !isEmpty(subscriptions));

  const checkTypeSubscription: (id: number) => SubscriptionCardProps['type'] = id => {
    if (SubscriptionsIds.Premium === id) return 'primary';
    else if (SubscriptionsIds.Standart === id) return 'secondary';
    else return 'default';
  };

  return (
    <div className={clsx(styles.subscriptionForm, className)}>
      <div className={styles.subscriptionFormBody}>
        {paymentStatus?.status !== 'success' && (
          <Fragment>
            {(loadingSubscription || isEmpty(subscriptions)) && (
              <ScrollToItemSlider className={styles.customSwiperSlide}>
                {Array(9)
                  .fill(1)
                  .map((_, index) => (
                    <SubscriptionSkeletonCard key={index} />
                  ))}
              </ScrollToItemSlider>
            )}

            {(!loadingSubscription || !isEmpty(subscriptions)) && (
              <ScrollToItemSlider
                className={styles.customSwiperSlide}
                handleChangeSlide={index => {
                  const subscription = subscriptions[index];
                  handlePurchaseStart(subscription);
                }}
              >
                {subscriptions?.map(subscription => (
                  <SubscriptionCard
                    key={subscription.id}
                    subscription={subscription}
                    type={checkTypeSubscription(subscription.id)}
                    handleClick={() => {
                      handlePurchaseStart(subscription);
                    }}
                  />
                ))}
              </ScrollToItemSlider>
            )}
          </Fragment>
        )}

        {paymentStatus?.status === 'success' ? (
          <ResultSubscriptionInfo subscription={selectedSubscription} />
        ) : (
          <SelectedSubscriptionInfo subscription={selectedSubscription} />
        )}
      </div>
    </div>
  );
};
