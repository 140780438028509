'use client';

import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext } from 'react';

import { ChildrenProps } from '@/types/common.types';

// =================================================================

export type TabsContextType = ChildrenProps & {
  isRoutesBased?: boolean;
  className?: string;
  activeIndex?: number;
  setActiveIndex?: Dispatch<SetStateAction<number>>;
  switcherClassName?: string;
  switcherActiveClassName?: string;
  switchersClassName?: string;
  variant?: 'primary' | 'secondary';
};

// =================================================================

const TabsContext = createContext<TabsContextType | null>(null);

// =================================================================

export const TabsProvider = (props: TabsContextType & { children: ReactNode }) => {
  const {
    isRoutesBased,
    className,
    variant = 'primary',
    switcherClassName,
    switcherActiveClassName,
    switchersClassName,
    children,
    activeIndex,
    setActiveIndex,
  } = props;

  return (
    <TabsContext.Provider
      value={{
        isRoutesBased,
        className,
        variant,
        switcherClassName,
        switcherActiveClassName,
        switchersClassName,
        children,
        activeIndex,
        setActiveIndex,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

// =================================================================

export const useTabsContext = () => {
  const context = useContext(TabsContext);

  if (!context) {
    throw new Error('useTabsContext must be used within a TabsContext');
  }

  return context;
};
