import type { SVGProps } from '@/types/common.types';

const GameOutlineSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M4.61945 12.4338C4.91048 11.2753 5.52909 10.2112 6.36776 9.38858M13.221 17.82C12.3633 18.1079 11.3597 18.2327 10.287 18.2327C9.21249 18.2327 8.20726 18.1075 7.34868 17.8186M14.2328 9.41451C15.0603 10.2358 15.6702 11.2933 15.9572 12.4434M11.8252 3.23585C12.057 2.3344 12.8947 1.66675 13.8927 1.66675C15.0697 1.66675 16.0239 2.59553 16.0239 3.74118C16.0239 4.62074 15.4615 5.37247 14.6676 5.67423M5.91663 5.67826C5.11712 5.37921 4.54968 4.62469 4.54968 3.74118C4.54968 2.59549 5.50384 1.66675 6.68089 1.66675C7.67916 1.66675 8.51712 2.33479 8.74865 3.23666M7.40396 10.1922C7.40396 8.64245 8.69464 7.38617 10.2868 7.38617C11.8789 7.38617 13.1696 8.64245 13.1696 10.1922M10.2862 7.53182V7.94606M17.4613 13.5504C16.9101 12.7919 16.0131 12.2984 15.0001 12.2984C13.3242 12.2984 11.9656 13.6494 11.9656 15.3159C11.9656 16.9824 13.3242 18.3334 15.0001 18.3334C16.0131 18.3334 16.9101 17.8399 17.4613 17.0815M14.9182 7.07803C14.9182 9.31432 12.8448 11.1272 10.2872 11.1272C7.72948 11.1272 5.65608 9.31432 5.65608 7.07803C5.65608 4.84175 7.72948 3.02888 10.2872 3.02888C12.8448 3.02888 14.9182 4.84175 14.9182 7.07803ZM8.6082 15.3159C8.6082 16.9824 7.24958 18.3334 5.57363 18.3334C3.89769 18.3334 2.53906 16.9824 2.53906 15.3159C2.53906 13.6494 3.89769 12.2984 5.57363 12.2984C7.24958 12.2984 8.6082 13.6494 8.6082 15.3159Z"
        stroke="url(#paint0_linear_6336_27275)"
        strokeWidth="1.09756"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6336_27275"
          x1="2.34329"
          y1="-5.71962"
          x2="-18.5381"
          y2="39.2246"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFF4D" />
          <stop offset="1" stopColor="#F46600" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GameOutlineSVG;
