import type { SVGProps } from '@/types/common.types';

const PaymentSuccessSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="202"
      height="202"
      viewBox="0 0 202 202"
      fill="none"
      {...props}
    >
      <rect
        x="27"
        y="11"
        width="148"
        height="148"
        rx="74"
        stroke="#34C763"
        strokeOpacity="0.1"
        strokeWidth="2"
      />
      <g filter="url(#filter0_d_6264_28726)">
        <circle cx="101" cy="85" r="51" fill="#34C763" />
        <circle cx="101" cy="85" r="50" stroke="url(#paint0_linear_6264_28726)" strokeWidth="2" />
      </g>
      <path
        d="M98.6584 108.491C98.4971 108.491 98.3375 108.457 98.1897 108.393C98.0419 108.328 97.909 108.233 97.7995 108.115L74.6428 83.0659C74.4884 82.8989 74.3861 82.6905 74.3483 82.4662C74.3105 82.242 74.3389 82.0116 74.4301 81.8032C74.5212 81.5949 74.6711 81.4176 74.8614 81.2931C75.0517 81.1686 75.2742 81.1023 75.5016 81.1023H86.648C86.8154 81.1023 86.9808 81.1383 87.1331 81.2076C87.2854 81.277 87.421 81.3783 87.5308 81.5045L95.2699 90.4081C96.1063 88.6202 97.7254 85.6433 100.567 82.0159C104.767 76.6532 112.58 68.7664 125.946 61.6468C126.205 61.5092 126.505 61.4735 126.789 61.5467C127.072 61.6199 127.318 61.7967 127.477 62.0422C127.637 62.2877 127.698 62.5841 127.65 62.8727C127.601 63.1614 127.446 63.4214 127.215 63.6014C127.164 63.6413 122.011 67.6999 116.079 75.1338C110.621 81.9748 103.364 93.161 99.7934 107.602C99.7307 107.856 99.5848 108.081 99.379 108.242C99.1732 108.404 98.9194 108.491 98.658 108.491L98.6584 108.491Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_6264_28726"
          x="0"
          y="0"
          width="202"
          height="202"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="16" />
          <feGaussianBlur stdDeviation="25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.203922 0 0 0 0 0.780392 0 0 0 0 0.386471 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6264_28726" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6264_28726"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_6264_28726"
          x1="101"
          y1="34"
          x2="101"
          y2="136"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PaymentSuccessSVG;
