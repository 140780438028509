'use client';

import { Fragment, useCallback } from 'react';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';

import { User } from '@/app/actions/auth/types';
import { useRouter } from '@/navigation';

import { Modal, ModalProps } from '@/components/Modal';

import { AuthVerification } from '@/contexts/VerificationContext/VerificationModal/AuthVerification';
import { SubscriptionVerification } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification';
import SubscriptionVerificationProvider from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts/SubscriptionVerificationProvider';
import { VerificationBanner } from '@/contexts/VerificationContext/VerificationModal/VerificationBanner';
import styles from '@/contexts/VerificationContext/VerificationModal/VerificationModal.module.scss';

// =================================================================

interface VerificationModalProps extends Omit<ModalProps, 'canOutsideClickClose' | 'children'> {
  isAuthVerification?: boolean;
  isSubscriptionVerification?: boolean;
  userCredentials?: User | null;
}

// =================================================================

export const VerificationModal = (props: VerificationModalProps) => {
  const { isAuthVerification, isSubscriptionVerification, onClose, userCredentials, ...rest } =
    props;

  const t = useTranslations('verification');

  const router = useRouter();
  const searchParams = useSearchParams();

  const handleCloseModal = useCallback(() => {
    onClose();

    const verificationQuery = searchParams.get('verification');

    if (verificationQuery && JSON.parse(verificationQuery) === true) {
      const currentSearchParams = new URLSearchParams(Array.from(searchParams.entries()));
      currentSearchParams.delete('verification');
      const query = currentSearchParams.toString();

      router.replace(`/?${query}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  const modalBodyClassName = clsx({
    [styles.authModalBody]: isAuthVerification,
    [styles.subscriptionModalBody]: isSubscriptionVerification,
  });

  return (
    <Modal
      canOutsideClickClose={false}
      onClose={handleCloseModal}
      {...rest}
      className={clsx({
        [styles.subcscriptionModal]: isSubscriptionVerification,
      })}
    >
      <Modal.Header
        onClose={handleCloseModal}
        className={clsx({
          [styles.subcscriptionModalHeader]: isSubscriptionVerification,
        })}
      >
        {isSubscriptionVerification && 'Оплата'}
      </Modal.Header>
      <Modal.Body className={modalBodyClassName}>
        {isAuthVerification && (
          <Fragment>
            <AuthVerification />
            <VerificationBanner />
          </Fragment>
        )}
        {isSubscriptionVerification && (
          <SubscriptionVerificationProvider userCredentials={userCredentials!}>
            <SubscriptionVerification />
          </SubscriptionVerificationProvider>
        )}
      </Modal.Body>
    </Modal>
  );
};
