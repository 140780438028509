'use client';

import { useState } from 'react';

import { useTranslations } from 'next-intl';

import { User } from '@/app/actions/auth/types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';

import { normalizePrise } from '@/helpers/transformations.helpers';

import { Button } from '@/components/Buttons';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/HasCard.module.scss';
import { useSubscriptionVerification } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts/SubscriptionVerificationProvider';

// =================================================================

export const HasRequiredBalance = () => {
  const t = useTranslations();

  const [isPending, setIsPending] = useState(false);
  const { subscription, isRenew, setUserCredentials, userCredentials } = useVerification();
  const { setPaymentStatus } = useSubscriptionVerification();
  const { writeOff, withdrawBalancePrise } = useSubscriptionVerification();

  const handlePayment = async () => {
    setIsPending(true);

    try {
      const {
        data: { message },
        statusText,
      } = await UserApi.purchaseSubscription(Number(subscription?.id), isRenew);
      setPaymentStatus &&
        setPaymentStatus({
          description: message,
          status: 'success',
          title: statusText,
        });

      const userData = {
        ...userCredentials,
        balance: Number(userCredentials?.balance) - Number(withdrawBalancePrise),
      } as User;
      setUserCredentials(userData);
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { message, statusText } = error.response;

      setPaymentStatus &&
        setPaymentStatus({
          description: message,
          status: 'error',
          title: statusText,
          lastActionFunction: handlePayment,
        });

      setIsPending(false);
    }
  };

  return (
    <Button
      variant="primary-gradient"
      size="lg"
      as="button"
      onClick={handlePayment}
      disabled={isPending}
      className="mt-4"
    >
      Оплатить с баланса
      <span className={styles.bage}>{`${normalizePrise(writeOff) || 0} UZS`}</span>
    </Button>
  );
};
