import { useState, JSX } from 'react';

import { useTranslations } from 'next-intl';

import HauseSVG from '@/icons/HauseSVG';
import PaymentErrorSVG from '@/icons/PaymentErrorSVG';
import PaymentSuccessSVG from '@/icons/PaymentSuccessSVG';
import RefreshSVG from '@/icons/RefreshSVG';
import { SVGProps } from '@/types/common.types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { normalizePrise } from '@/helpers/transformations.helpers';

import { Button } from '@/components/Buttons';
import { ResultNotFound } from '@/components/ResultNotFound';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/PaymentStatus.module.scss';
import { PaymentResponseStatusProps } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts';
import {
  StatusType,
  useSubscriptionVerification,
} from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts/SubscriptionVerificationProvider';

// =================================================================

interface PaymentStatusProps extends Pick<PaymentResponseStatusProps, 'description' | 'title'> {
  status: StatusType;
}

// =================================================================

const statusIcons: Record<StatusType, (props: SVGProps) => JSX.Element> = {
  error: PaymentErrorSVG,
  success: PaymentSuccessSVG,
};

// =================================================================

export const PaymentStatus = (props: PaymentStatusProps) => {
  const { status } = props;
  const t = useTranslations('verification');

  const IconComponent = statusIcons[status];

  const [isPending, setIsPending] = useState(false);

  const { paymentStatus, writeOff } = useSubscriptionVerification();

  const {
    closeVerificationModal,
    subscriptionCallbackOnSuccess,
    subscription: selectedSubscription,
  } = useVerification();

  const onSuccessAfterPayment = () => {
    closeVerificationModal();
    if (typeof subscriptionCallbackOnSuccess === 'function' && status === 'success') {
      subscriptionCallbackOnSuccess();
    }
  };

  const refetchPaymentFunction = async () => {
    setIsPending(true);
    try {
      if (typeof paymentStatus?.lastActionFunction === 'function') {
        await paymentStatus?.lastActionFunction();
      }
    } finally {
      setIsPending(false);
    }
  };

  const isSuccess = status === 'success';
  const title = isSuccess ? t('successPaymentResponse') : t('errorPaymentResponse');
  const description = isSuccess
    ? t('successPaymentResponseDescription', { tariff: selectedSubscription?.title })
    : t('errorPaymentResponseDescription');

  return (
    <div className={styles.paymentStatusWrapper}>
      <ResultNotFound
        icon={
          <div className={styles.iconWrapper}>
            <IconComponent />
            <div className={styles.writeOff}>
              {normalizePrise(selectedSubscription?.price as number)} UZS
            </div>
          </div>
        }
        title={title}
        description={description}
      />

      <div className={styles.statusActions}>
        <Button
          as="button"
          onClick={onSuccessAfterPayment}
          variant={status === 'error' ? 'secondary' : 'primary'}
          size="lg"
          className="w-100"
        >
          На главную <HauseSVG />
        </Button>

        {status === 'error' && (
          <Button
            as="button"
            onClick={refetchPaymentFunction}
            variant={'primary'}
            size="lg"
            disabled={isPending}
          >
            Повторить платёж <RefreshSVG />
          </Button>
        )}
      </div>
    </div>
  );
};
