import type { SVGProps } from '@/types/common.types';

const RefreshSecondarySVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M13.3333 7.33334C13.2185 6.5072 12.9138 5.7189 12.4433 5.03024C11.9727 4.34158 11.349 3.77129 10.6211 3.36408C9.89314 2.95688 9.08078 2.72384 8.24768 2.68322C7.41459 2.64261 6.58342 2.79554 5.81933 3.13001M4.21267 4.21268C3.69196 4.72051 3.27946 5.3285 3 6.00001M2.66667 3.33334V6.00001H5.33333M2.66667 8.66667C2.80408 9.65544 3.21288 10.5868 3.84768 11.3572C4.48248 12.1276 5.31841 12.707 6.26264 13.031C7.20688 13.355 8.22244 13.411 9.19653 13.1926C10.1706 12.9742 11.0651 12.4901 11.7807 11.794M13.3333 10.6667V10H12.6667M2 2L14 14"
        stroke="white"
        strokeOpacity="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshSecondarySVG;
