'use client';

import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useTranslations } from 'next-intl';
import * as yup from 'yup';

import { User } from '@/app/actions/auth/types';
import { ConfirmationForm } from '@/app/actions/bank-card/types';
import EditSVG from '@/icons/EditSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';

import { Button, TextButton } from '@/components/Buttons';
import { Form } from '@/components/Form';

import { useNotification } from '@/contexts/NotificationContext';
import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/CardConfirmation.module.scss';
import { useBankCard } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/contexts/BankCardProvider';

// =================================================================

export const CardConfirmation = () => {
  const t = useTranslations();

  const { userCredentials, setUserCredentials } = useVerification();

  const [timer, setTimer] = useState<number>(59);

  const { notification } = useNotification();

  const { responseMessage, data, purchaseSubscriptionWithCard, setHasOTP } = useBankCard();
  const { subscription } = useVerification();

  const schema = useMemo(
    () =>
      yup
        .object({
          code: yup
            .string()
            .trim()
            .matches(/[0-9]{5}/, t('formWarningMassage.invalidCode'))
            .required(t('formWarningMassage.requiredField')),
        })
        .required(),
    [t],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer) {
        setTimer(timer => timer - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const transformedTime = ('00' + timer).slice(-2);

  const resendCode = async () => {
    if (!data) return;
    try {
      setTimer(59);
      await UserApi.addBankCard(data);
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { message } = error.response;
      notification('error', message);
    }
  };

  const methods = useForm<ConfirmationForm>({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData: ConfirmationForm) => {
    try {
      const response = await UserApi.cardConfrimOTP(formData.code);
      const userData = { ...userCredentials, card: response.data } as User;
      setUserCredentials(userData);

      if (isEmpty(subscription)) {
        setHasOTP(false);
      } else {
        setHasOTP(true);
        await purchaseSubscriptionWithCard();
      }
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      const { message } = error.response;
      notification('error', message);
    }
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={methods.handleSubmit(onSubmit)}
        isSubmitting={methods.formState.isSubmitting}
        autoComplete="off"
        className={styles.confirmationCodeWrapper}
      >
        <div className={styles.modalHeader}>
          <h3 className={styles.title}>Введите код</h3>
          <p className={styles.description}>
            Введите 6-значный код подтверждения отправленный на введённый номер телефона
          </p>

          <div className={styles.responseMessage}>
            <p>{responseMessage}</p>
            <button type="button" className={styles.iconWrapper} onClick={() => setHasOTP(false)}>
              <EditSVG />
            </button>
          </div>
        </div>
        <Form.Body className={styles.modalBody}>
          <Form.Code
            name="code"
            length={6}
            placeholder="-"
            className={styles.codeInput}
            autoFocus
          />

          <TextButton
            as="button"
            disabled={Boolean(timer)}
            onClick={resendCode}
            className={styles.resetButton}
          >
            {t('verification.resend')}
            {Boolean(timer) && <span className={styles.timer}>{`00:${transformedTime}`}</span>}
          </TextButton>
        </Form.Body>
        <Form.Footer>
          <Button
            size="lg"
            variant="primary"
            as="button"
            type="submit"
            disabled={methods.formState.isSubmitting}
            className={styles.addCardButton}
          >
            {t('verification.sendCode')}
          </Button>
        </Form.Footer>
      </Form>
    </FormProvider>
  );
};
