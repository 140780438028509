'use client';

import { ElementType, ComponentPropsWithRef } from 'react';

import clsx from 'clsx';

import styles from '@/components/Tabs/Tabs.module.scss';
import { useTabsContext } from '@/components/Tabs/context/TabsContext';

// =================================================================

type TabsSwitcherProps<E extends ElementType> = {
  as?: E;
  isActive?: boolean;
  count?: number;
} & ComponentPropsWithRef<E>;

// =================================================================

export const TabsSwitcher = <E extends ElementType>(props: TabsSwitcherProps<E>) => {
  const { as: Element, isActive, children, count, ...rest } = props;
  const {
    variant = 'primary',
    switcherClassName,
    switcherActiveClassName,
    isRoutesBased,
  } = useTabsContext();
  return (
    <li className={clsx(styles.tabsSwitcherWrapper, styles[variant])}>
      <Element
        className={clsx(switcherClassName, isActive && switcherActiveClassName, {
          [styles[variant]]: true,
          [styles.tabsSwitcher]: true,
          [styles.active]: !isRoutesBased && isActive,
        })}
        {...rest}
      >
        {children}

        {Boolean(count) && <span className={styles.count}>{count}</span>}
      </Element>
    </li>
  );
};
