import type { SVGProps } from '@/types/common.types';

const RefreshSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M20 11C19.7554 9.24017 18.9391 7.60961 17.6766 6.35945C16.4142 5.10928 14.7758 4.30887 13.0137 4.0815C11.2516 3.85414 9.46362 4.21243 7.9252 5.1012C6.38678 5.98996 5.18325 7.35989 4.5 8.99995M4 4.99995V8.99995H8M4 12.9999C4.24456 14.7597 5.06093 16.3903 6.32336 17.6404C7.58579 18.8906 9.22424 19.691 10.9863 19.9184C12.7484 20.1458 14.5364 19.7875 16.0748 18.8987C17.6132 18.0099 18.8168 16.64 19.5 14.9999M20 18.9999V14.9999H16"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshSVG;
