'use client';

import { ChildrenProps } from '@/types/common.types';

import { useTabsContext } from '@/components/Tabs/context/TabsContext';

// =================================================================

export const TabsPanels = (props: ChildrenProps) => {
  const { children } = props;
  const { activeIndex = 0 } = useTabsContext();

  return Array.isArray(children) ? children[activeIndex] : children;
};

// =================================================================

export const TabsPanel = (props: ChildrenProps) => props.children;
