'use client';

import React, { Fragment, useState } from 'react';

import { useTranslations } from 'next-intl';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';

import { normalizePrise } from '@/helpers/transformations.helpers';

import { Button } from '@/components/Buttons';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/HasCard.module.scss';
import { useSubscriptionVerification } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts';

import { HasCard as UsersCard } from '@/views/UserProfileViews/AddCardView/HasCard';

// =================================================================

export const HasCard = () => {
  const t = useTranslations('verification');
  const [isPending, setIsPending] = useState(false);

  const { subscription, isRenew } = useVerification();
  const { userCredentials, setPaymentStatus } = useSubscriptionVerification();
  const { writeOff } = useSubscriptionVerification();

  const handlePayment = async () => {
    setIsPending(true);

    try {
      const {
        data: { message },
        statusText,
      } = await UserApi.cardPayment(Number(subscription?.id), isRenew);
      setPaymentStatus &&
        setPaymentStatus({
          description: message,
          status: 'success',
          title: statusText,
        });
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { message, statusText } = error.response;

      setPaymentStatus &&
        setPaymentStatus({
          description: message,
          status: 'error',
          title: statusText,
          lastActionFunction: handlePayment,
        });

      setIsPending(false);
    }
  };

  return (
    <Fragment>
      <UsersCard card={userCredentials?.card} />

      <Button
        variant="primary-gradient"
        size="lg"
        as="button"
        onClick={handlePayment}
        disabled={isPending}
        className="mt-4"
      >
        Оплатить
        <span className={styles.bage}>{`${normalizePrise(writeOff) || 0} UZS`}</span>
      </Button>
    </Fragment>
  );
};
