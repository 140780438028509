'use client';

import React, { useContext, useMemo, useState } from 'react';
import { useUnmount } from 'react-use';

import { User } from '@/app/actions/auth/types';
import { ChildrenProps } from '@/types/common.types';

import { calculatePayment } from '@/helpers/calculate-payment.helpers';

import { ResultNotFoundProps } from '@/components/ResultNotFound';

import { useStore } from '@/contexts/StoreContext';

// =================================================================

interface SubscriptionVerificationProviderProps extends ChildrenProps {
  userCredentials: User;
}

// =================================================================

export type StatusType = 'error' | 'success';

export interface CommonResponseStatusProps
  extends Pick<ResultNotFoundProps, 'description' | 'title'> {}

export type PaymentResponseStatusProps = CommonResponseStatusProps &
  (
    | {
        status?: 'error';
        lastActionFunction: () => void;
      }
    | {
        status?: 'success';
        lastActionFunction?: never;
      }
  );
interface SubscriptionProviderStore
  extends Omit<SubscriptionVerificationProviderProps, 'children'> {
  writeOff: number | null;
  withdrawCardPrise: number;
  withdrawBalancePrise: number;
  balance?: number;
  paymentStatus?: PaymentResponseStatusProps;
  setPaymentStatus?: React.Dispatch<React.SetStateAction<PaymentResponseStatusProps>>;
}

// =================================================================

const SubscriptionVerificationContext = React.createContext<SubscriptionProviderStore | null>(null);

// =================================================================

const MIN_SUM = 100;

// =================================================================

const SubscriptionVerificationProvider = (props: SubscriptionVerificationProviderProps) => {
  const { children, userCredentials, ...rest } = props;
  const { subscription, setSubscription } = useStore(store => store.subscriptionStore);
  const [paymentStatus, setPaymentStatus] = useState<PaymentResponseStatusProps>({});

  const { fromCard: withdrawCardPrise, fromBalance: withdrawBalancePrise } = useMemo(() => {
    if (!subscription)
      return {
        fromCard: 0,
        fromBalance: 0,
      };
    return calculatePayment({
      minCardSum: MIN_SUM,
      subscriptionPrice: Number(subscription?.price),
      balance: userCredentials?.balance,
      forcePayWithCard: subscription?.forcePayWithCard,
    });
  }, [subscription, userCredentials?.balance]);

  const writeOff = useMemo(() => {
    if (subscription?.forcePayWithCard && !subscription) return null;
    const subscriptionPrice = Number(subscription?.price);
    return subscriptionPrice - userCredentials?.balance < MIN_SUM
      ? subscriptionPrice
      : subscriptionPrice - userCredentials?.balance;
  }, [subscription, userCredentials?.balance]);

  useUnmount(() => {
    setSubscription(null);
  });

  return (
    <SubscriptionVerificationContext.Provider
      value={{
        writeOff,
        withdrawCardPrise,
        withdrawBalancePrise,
        userCredentials,
        balance: userCredentials?.balance,
        paymentStatus,
        setPaymentStatus,
        ...rest,
      }}
    >
      {children}
    </SubscriptionVerificationContext.Provider>
  );
};

export default SubscriptionVerificationProvider;

// =================================================================

export const useSubscriptionVerification = () => {
  const context = useContext(SubscriptionVerificationContext);

  if (!context) {
    throw new Error(
      'useSubscriptionVerification should be used within SubscriptionVerificationProvider',
    );
  }

  return context;
};
