'use client';

import { Fragment, useMemo } from 'react';
import { useMedia } from 'react-use';

import { isEmpty } from 'lodash';
import { useLocale } from 'next-intl';

import { breakpoints } from '@/app.config';
import { usePathname } from '@/navigation';
import type { Locale } from '@/types/common.types';

import { useShowcasedSubscriptions } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/hooks/useShowcasedSubscriptions';
import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { TransformedSubscription } from '@/api/types/user.types';

import { Tabs } from '@/components/Tabs';

import { PaymentStatus } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/PaymentStatus';
import { PaymentSystems } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/PaymentSystems';
import { UserPayments } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/UserPayments';
import { SubscriptionRow } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SubscriptionRow';
import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SubscriptionVerification.module.scss';
import { useSubscriptionVerification } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts';

// =================================================================

const PROFILE_BASE_URL = '/user-profile';
const SUBSCRIPTIONS_BASE_URL = '/subscriptions';

// =================================================================

export const SubscriptionVerification = () => {
  const locale = useLocale() as Locale;

  const isUz = locale === 'uz';

  const pathname = usePathname();

  const { subscription, loadingSubscription } = useShowcasedSubscriptions();

  const { paymentStatus } = useSubscriptionVerification();

  const isExtraLarge = useMedia(breakpoints.extraLarge, true);

  const {
    subscriptionTitle,
    subscription: selectedsubscription,
    selectToBuySubscription,
  } = useVerification();

  const isProfilePage = useMemo(() => {
    return pathname.startsWith(PROFILE_BASE_URL) || pathname === SUBSCRIPTIONS_BASE_URL;
  }, [pathname]);

  // TODO : this title should be sent by the backend
  const title = !isProfilePage
    ? `${subscriptionTitle} ${selectedsubscription?.title || ''} ${isUz ? 'obunasi bilan tomosha qiling' : ''}`
    : undefined;

  return (
    <Fragment>
      <div className={styles.subscriptionModalBody}>
        {!isExtraLarge && (
          <SubscriptionRow
            subscriptions={
              isProfilePage
                ? (Array(selectToBuySubscription) as TransformedSubscription[])
                : subscription
            }
            loadingSubscription={isProfilePage ? false : loadingSubscription}
            title={title}
          />
        )}

        {!isEmpty(paymentStatus) && <PaymentStatus status={paymentStatus.status!} />}
        {isEmpty(paymentStatus) && (
          <Tabs
            variant="secondary"
            className={styles.paymentTabs}
            switchersClassName={styles.paymentSwitchers}
          >
            <Tabs.Switchers>
              <Tabs.Switcher>Карты</Tabs.Switcher>
              <Tabs.Switcher>Платёжные системы</Tabs.Switcher>
            </Tabs.Switchers>
            <Tabs.Panels>
              <Tabs.Panel>
                <div className={styles.paymentsWrapper}>
                  <UserPayments />
                </div>
              </Tabs.Panel>
              <Tabs.Panel>
                <div className={styles.paymentsWrapper}>
                  <PaymentSystems />
                </div>
              </Tabs.Panel>
            </Tabs.Panels>
          </Tabs>
        )}
      </div>

      {isExtraLarge && (
        <div className={styles.tariffsCard}>
          <div className={styles.tariffsCardWrapper}>
            <SubscriptionRow
              subscriptions={
                isProfilePage
                  ? (Array(selectToBuySubscription) as TransformedSubscription[])
                  : subscription
              }
              loadingSubscription={isProfilePage ? false : loadingSubscription}
              title={title}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};
