/* eslint-disable @next/next/no-img-element */
import { Fragment, ReactNode, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import clsx from 'clsx';

import RefreshSVG from '@/icons/RefreshSVG';
import RefreshSecondarySVG from '@/icons/RefreshSecondarySVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { TransformedSubscription } from '@/api/types/user.types';

import { normalizePrise } from '@/helpers/transformations.helpers';

import { FormCheckbox } from '@/components/Form/FormCheckbox';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SelectedSubscriptionInfo.module.scss';
import { useSubscriptionVerification } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts';

// =================================================================

interface SelectedSubscriptionInfoProps {
  subscription: TransformedSubscription | null;
}

interface subscriptionInfoLineProps {
  label: string;
  value?: ReactNode;
  size: 'sm' | 'md';
}

// =================================================================

export const SubscriptionInfoLine = (props: subscriptionInfoLineProps) => {
  const { label, value, size } = props;
  return (
    <li className={styles.subscriptionInfoLine}>
      <p className={clsx(styles.label, styles[size])}>{label}</p>
      <span className={styles.line} />
      <div className={clsx(styles.value, styles[size])}>{value}</div>
    </li>
  );
};

// =================================================================

function addDaysToDate(dateString: string, daysToAdd: number): string {
  const [day, month, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);

  date.setDate(date.getDate() + daysToAdd);

  const newDay = String(date.getDate()).padStart(2, '0');
  const newMonth = String(date.getMonth() + 1).padStart(2, '0');
  const newYear = date.getFullYear();

  return `${newDay}.${newMonth}.${newYear}`;
}

// =================================================================

export const SelectedSubscriptionInfo = (props: SelectedSubscriptionInfoProps) => {
  const { subscription } = props;

  const currDate = new Date().toLocaleDateString('en-GB');
  const { balance, writeOff } = useSubscriptionVerification();
  const { isRenew, setRenew } = useVerification();

  const methods = useForm({
    defaultValues: {
      is_renew: isRenew,
      bonus: false,
    },
  });

  useEffect(() => {
    setRenew(methods.watch('is_renew'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch('is_renew')]);

  return (
    <FormProvider {...methods}>
      <div className={styles.subscriptionInfo}>
        <ul className={styles.subscriptionList}>
          <SubscriptionInfoLine label="Название плана:" value={subscription?.title} size="sm" />
          <SubscriptionInfoLine label="Цена плана:" value={subscription?.priceStr} size="sm" />
          <SubscriptionInfoLine label="Период:" value={subscription?.daysStr} size="sm" />
          <span className={clsx(styles.lineRow, 'my-3')} />
          <SubscriptionInfoLine
            label="Начало и конец:"
            value={`${currDate.replaceAll('/', '.')} - ${addDaysToDate(currDate, 30)}`}
            size="sm"
          />
          <SubscriptionInfoLine
            label="Автопродление:"
            value={
              <span
                className={clsx(styles.label, {
                  [styles.active]: methods.watch('is_renew'),
                })}
              >
                {methods.watch('is_renew') ? (
                  <Fragment>
                    Включено <RefreshSVG height={16} width={16} />
                  </Fragment>
                ) : (
                  <Fragment>
                    Выключено <RefreshSecondarySVG />
                  </Fragment>
                )}
              </span>
            }
            size="sm"
          />
          {/* <SubscriptionInfoLine label="Автопродления через:" value={'30 дней'} size="sm" /> */}
          <SubscriptionInfoLine
            label="Баланс:"
            value={`${normalizePrise(balance || 0)} UZS`}
            size="sm"
          />
          {/* <SubscriptionInfoLine
            label="Исползовать бонус"
            value={
              <FormCheckbox
                label={''}
                variant="toggle"
                name={'bonus'}
                shape="round"
                className={styles.dropdownItem}
              />
            }
            size="sm"
          /> */}
          <span className={clsx(styles.lineRow, 'my-3')} />
          <SubscriptionInfoLine
            label="Итого к оплате"
            value={`${normalizePrise(writeOff) || 0} UZS`}
            size="md"
          />
          <li className={styles.autoRenew}>
            Автопродление плана
            <FormCheckbox
              label={''}
              variant="toggle"
              name={'is_renew'}
              shape="round"
              className={styles.dropdownItem}
              checked={subscription?.forcePayWithCard || isRenew}
              disabled={!subscription?.isBuyable}
            />
          </li>
        </ul>
      </div>
    </FormProvider>
  );
};

// =================================================================
export const ResultSubscriptionInfo = (props: SelectedSubscriptionInfoProps) => {
  const { subscription } = props;

  const currDate = new Date().toLocaleDateString('en-GB');
  const { balance, writeOff } = useSubscriptionVerification();
  const { isRenew } = useVerification();

  return (
    <div className={styles.subscriptionInfo}>
      <h2 className={styles.title}>{`${normalizePrise(writeOff) || 0} UZS`}</h2>
      <p className={styles.description}>Оплачено для покупки плана</p>
      <ul className={styles.resultSubscriptionList}>
        <SubscriptionInfoLine label="Название плана:" value={subscription?.title} size="sm" />
        <SubscriptionInfoLine label="Цена плана:" value={subscription?.priceStr} size="sm" />
        <SubscriptionInfoLine label="Период:" value={subscription?.daysStr} size="sm" />
        <span className={clsx(styles.lineRow, 'my-3')} />
        <SubscriptionInfoLine
          label="Начало и конец:"
          value={`${currDate.replaceAll('/', '.')} - ${addDaysToDate(currDate, 30)}`}
          size="sm"
        />
        <SubscriptionInfoLine
          label="Автопродление:"
          value={
            <span
              className={clsx(styles.label, {
                [styles.active]: isRenew,
              })}
            >
              {isRenew ? (
                <Fragment>
                  Включено <RefreshSVG height={16} width={16} />
                </Fragment>
              ) : (
                <Fragment>
                  Выключено <RefreshSecondarySVG />
                </Fragment>
              )}
            </span>
          }
          size="sm"
        />
        <SubscriptionInfoLine
          label="Баланс:"
          value={`${normalizePrise(balance || 0)} UZS`}
          size="sm"
        />
      </ul>
    </div>
  );
};
