'use client';

import { Fragment } from 'react';

import { HasCard } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/HasCard';
import { HasNotCard } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/HasNotCard';
import { useSubscriptionVerification } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts';

export const PaymentWithCard = () => {
  const { userCredentials } = useSubscriptionVerification();

  return (
    <Fragment>
      {!userCredentials?.card && <HasNotCard />}

      {userCredentials?.card && <HasCard />}
    </Fragment>
  );
};
