'use client';

import React, { Fragment, useState } from 'react';

import { useTranslations } from 'next-intl';

import type { User } from '@/app/actions/auth/types';
import HumoCardSVG from '@/icons/HumoCardSVG';
import TrashbinSVG from '@/icons/TrashbinSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';

import { Alert } from '@/components/Alert';
import { Button, IconButton } from '@/components/Buttons';

import { useNotification } from '@/contexts/NotificationContext';

import styles from '@/views/UserProfileViews/AddCardView/HasCard.module.scss';

// =================================================================

interface HasCardProps {
  card: User['card'];
}

// =================================================================

export const HasCard = (props: HasCardProps) => {
  const { card } = props;

  const t = useTranslations('page.userProfile');
  const { userCredentials, setUserCredentials } = useVerification();
  const { notification } = useNotification();

  const [deleteModal, setDeleteModal] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDelete = async () => {
    setIsPending(true);

    try {
      const { message } = await UserApi.deleteCard(card?.id as number);

      setIsPending(false);
      setDeleteModal(false);
      const userData = { ...userCredentials, card: null } as User;
      setUserCredentials(userData);
      notification('success', message);
    } catch (error) {
      if (!error.response) return;

      const { message } = error.response;
      notification('error', message);
      setIsPending(false);
    }
  };

  return (
    <React.Fragment>
      <div className={styles.bankCard}>
        <div className={styles.iconWrapper}>
          <HumoCardSVG />
        </div>
        <div className={styles.bankCardInfo}>
          <p className={styles.number}>{card?.card_number}</p>
          <p className={styles.userInfo}>{card?.owner_name}</p>
        </div>

        <IconButton
          as="button"
          icon={TrashbinSVG}
          iconWidth={24}
          iconHeight={24}
          title="Удалить из истории"
          onClick={handleOpenDeleteModal}
          className={styles.trashbinIcon}
        />
      </div>

      <Alert
        status="error"
        isOpen={deleteModal}
        size="sm"
        title={'Удалить карту'}
        descritption={t('deleteWarningMessage')}
        icon={<TrashbinSVG width={40} height={40} />}
        footer={
          <Fragment>
            <Button
              as="button"
              variant="secondary"
              onClick={handleCloseDeleteModal}
              className="mx-2 w-fill"
              size="lg"
              disabled={isPending}
            >
              {t('cancel')}
            </Button>
            <Button
              size="lg"
              as="button"
              variant="secondary-red"
              onClick={handleDelete}
              disabled={isPending}
            >
              {t('yes')}
            </Button>
          </Fragment>
        }
        onClose={handleCloseDeleteModal}
      />
    </React.Fragment>
  );
};
