import type { SVGProps } from '@/types/common.types';

const UzContentSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      {...props}
    >
      <path
        d="M7.08133 0H9.64774V7.6432C9.64774 8.52652 9.45899 9.29605 9.08147 9.95178C8.70396 10.6037 8.17751 11.109 7.50214 11.4677C6.82677 11.8226 6.0423 12 5.14874 12C4.24478 12 3.45511 11.8226 2.77974 11.4677C2.10437 11.109 1.57966 10.6037 1.20561 9.95178C0.831557 9.29605 0.644531 8.52652 0.644531 7.6432V0H3.21614V7.39441C3.21614 7.80328 3.29579 8.16779 3.45511 8.48795C3.61789 8.8081 3.84475 9.05882 4.13568 9.24012C4.42661 9.42141 4.76429 9.51205 5.14874 9.51205C5.53318 9.51205 5.86913 9.42141 6.1566 9.24012C6.44753 9.05882 6.67438 8.8081 6.83716 8.48795C6.99994 8.16779 7.08133 7.80328 7.08133 7.39441V0Z"
        fill="url(#paint0_linear_6336_27281)"
      />
      <path
        d="M11.2232 11.8496V10.2179L16.3924 2.32594H11.2284V0H19.6445V1.63163L14.4753 9.52363H19.6393V11.8496H11.2232Z"
        fill="url(#paint1_linear_6336_27281)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6336_27281"
          x1="0.39526"
          y1="-5.31818"
          x2="-9.27415"
          y2="31.4864"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFF4D" />
          <stop offset="1" stopColor="#F46600" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6336_27281"
          x1="0.39526"
          y1="-5.31818"
          x2="-9.27415"
          y2="31.4864"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFF4D" />
          <stop offset="1" stopColor="#F46600" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UzContentSVG;
