'use client';

import React, { createContext, useContext } from 'react';

import { ChildrenProps } from '@/types/common.types';

import { UserApi } from '@/api/domains/user-api';

import { useStore } from '@/contexts/StoreContext';
import { useSubscriptionVerification } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts/SubscriptionVerificationProvider';

// =================================================================

interface BankCardProviderProps extends ChildrenProps {
  responseMessage: string;
  data: any;
  hasOTP: boolean;
  setData: Function;
  setHasOTP: (hasOTP: boolean) => void;
  setResponseMessage: (responseMessage: string) => void;
  purchaseSubscriptionWithCard: () => void | Promise<void>;
}

// =================================================================

const BankCardContext = createContext<Omit<BankCardProviderProps, 'children'> | null>(null);

const BankCardProvider = (props: Omit<BankCardProviderProps, 'purchaseSubscriptionWithCard'>) => {
  const { children, setHasOTP, ...rest } = props;

  const { isRenew } = useStore(store => store.verificationStore);
  const { subscription } = useStore(store => store.subscriptionStore);
  const { setPaymentStatus } = useSubscriptionVerification();

  const purchaseSubscriptionWithCard = async () => {
    try {
      const {
        statusText,
        data: { message },
      } = await UserApi.cardPayment(Number(subscription?.id), isRenew);
      setPaymentStatus &&
        setPaymentStatus({
          description: message,
          status: 'success',
          title: statusText,
        });
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      const { message, statusText } = error.response;
      setPaymentStatus &&
        setPaymentStatus({
          description: message,
          status: 'error',
          title: statusText,
          lastActionFunction: purchaseSubscriptionWithCard,
        });
    }
  };

  const value = {
    ...rest,
    setHasOTP,
    purchaseSubscriptionWithCard,
  };

  return <BankCardContext.Provider value={value}>{children}</BankCardContext.Provider>;
};

export default BankCardProvider;

// =================================================================

export const useBankCard = () => {
  const context = useContext(BankCardContext);

  if (!context) {
    throw new Error('useBankCard should be used within BankCardProvider');
  }

  return context;
};
