'use client';

import React, { ReactNode, useState } from 'react';

import clsx from 'clsx';

import styles from '@/components/Tabs/Tabs.module.scss';
import { TabsPanel, TabsPanels } from '@/components/Tabs/TabsPanels';
import { TabsSwitcher } from '@/components/Tabs/TabsSwitcher';
import { TabsSwitchers } from '@/components/Tabs/TabsSwitchers';
import { TabsContextType, TabsProvider } from '@/components/Tabs/context/TabsContext';

// =================================================================

export const Tabs = (props: TabsContextType & { children: ReactNode }) => {
  const { className, children, ...rest } = props;

  const [activeIndex, setActiveIndex] = useState<number>(0);
  return (
    <div className={clsx(styles.tabs, className)}>
      <TabsProvider {...rest} activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
        {children}
      </TabsProvider>
    </div>
  );
};

// =================================================================

Tabs.Switchers = TabsSwitchers;
Tabs.Switcher = TabsSwitcher;
Tabs.Panels = TabsPanels;
Tabs.Panel = TabsPanel;
