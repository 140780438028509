import type { SVGProps } from '@/types/common.types';

const HauseSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M9.5 21V15C9.5 14.4696 9.71071 13.9609 10.0858 13.5858C10.4609 13.2107 10.9696 13 11.5 13H13.5C14.0304 13 14.5391 13.2107 14.9142 13.5858C15.2893 13.9609 15.5 14.4696 15.5 15V21M5.5 12H3.5L12.5 3L21.5 12H19.5V19C19.5 19.5304 19.2893 20.0391 18.9142 20.4142C18.5391 20.7893 18.0304 21 17.5 21H7.5C6.96957 21 6.46086 20.7893 6.08579 20.4142C5.71071 20.0391 5.5 19.5304 5.5 19V12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HauseSVG;
