'use client';

import { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import * as yup from 'yup';

import { AddCardForm } from '@/app/actions/bank-card/types';
import BankCardSVG from '@/icons/BankCardSVG';
import HasNotCardSVG from '@/icons/HasNotCardSVG';
import PlusSVG from '@/icons/PlusSVG';
import UserPhoneSVG from '@/icons/UserPhoneSVG';

import { UserApi } from '@/api/domains/user-api';

import { UZ_PHONE_CODE } from '@/constants/phone-number.constants';

import { Button } from '@/components/Buttons';
import { Form } from '@/components/Form';
import { ResultNotFound } from '@/components/ResultNotFound';

import { useNotification } from '@/contexts/NotificationContext';
import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/HasNotCard.module.scss';
import { HasRequiredBalance } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/HasRequiredBalance';
import { useBankCard } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/contexts';
import { useSubscriptionVerification } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts/SubscriptionVerificationProvider';

// =================================================================

export const HasNotCard = () => {
  const t = useTranslations();

  const [open, setOpen] = useState<boolean>(false);
  const { setHasOTP, setData, setResponseMessage } = useBankCard();

  const { withdrawBalancePrise, withdrawCardPrise } = useSubscriptionVerification();

  const { notification } = useNotification();

  const schemaBankCard = useMemo(
    () =>
      yup.object().shape({
        card_number: yup
          .string()
          .required(t('formWarningMassage.enterCardDetails'))
          .matches(/^\d{4} \d{4} \d{4} \d{4}$/, t('formWarningMassage.invalidCardNumberFormat')),
        card_exp: yup
          .string()
          .required(t('formWarningMassage.enterCardDetails'))
          .test('expireDateFormat', t('formWarningMassage.invalidTermFormat'), value => {
            if (!value) return false; // If value is null or empty, it's invalid
            const parts = value.split('/');
            if (parts.length !== 2) return false; // Date should have two parts: month and year
            const month = parseInt(parts[0], 10);
            const year = parseInt(parts[1], 10);
            // Validate month and year
            return (
              month >= 1 &&
              month <= 12 &&
              year >= 0 &&
              year <= 99 &&
              /^\d{2}$/.test(parts[1].trim())
            );
          }),
        user_phone: yup
          .string()
          .trim()
          .required(t('formWarningMassage.requiredField'))
          .matches(/[0-9 ]{3}[0-9 ]{4}[0-9 ]{3}[0-9]{2}/, t('formWarningMassage.incorrectNumber')),
      }),
    [t],
  );

  const methods = useForm<AddCardForm>({
    defaultValues: {
      card_number: '',
      card_exp: '',
    },
    resolver: yupResolver(schemaBankCard),
    mode: 'onChange',
  });

  const onSubmit = async (data: AddCardForm) => {
    const transformedBankCardNumber = data.card_number.split(' ').join('');

    const transformedExpireDate = data.card_exp.split('/').reverse().join('').trim();

    const normalizedPhone = UZ_PHONE_CODE + data.user_phone.replace(/\s/g, '');
    try {
      const { message } = await UserApi.addBankCard({
        card_exp: transformedExpireDate,
        card_number: transformedBankCardNumber,
        user_phone: normalizedPhone,
      });

      setData({
        card_number: transformedBankCardNumber,
        card_exp: transformedExpireDate,
      });
      setHasOTP(true);
      setResponseMessage(message);
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { message } = error.response;
      notification('error', message);
    }
  };

  const handleOpen = () => setOpen(true);

  const isOnlyBalancePurchasing = useMemo(
    () => withdrawBalancePrise > 0 && withdrawCardPrise <= 0,
    [withdrawBalancePrise, withdrawCardPrise],
  );

  return (
    <div className={styles.hasNotCardWrapper}>
      {!open && (
        <ResultNotFound
          description="Они появятся здесь, как только вы добавите их."
          title="У вас пока нет карт"
          icon={<HasNotCardSVG />}
        >
          {isOnlyBalancePurchasing && (
            <Button
              as="button"
              onClick={handleOpen}
              variant="text-yellow"
              size="lg"
              type={'submit'}
              disabled={methods.formState.isSubmitting}
            >
              Добавить картy <PlusSVG />
            </Button>
          )}
        </ResultNotFound>
      )}
      {open && (
        <FormProvider {...methods}>
          <Form
            onSubmit={methods.handleSubmit(onSubmit)}
            isSubmitting={methods.formState.isSubmitting}
            autoComplete="off"
          >
            <div className={styles.addCardWrapper}>
              <Form.Body className={styles.modalBody}>
                <Row>
                  <Col xs={12}>
                    <Form.Label name="card_number" title="Номер карты">
                      <Form.NumberInput
                        autoFocus
                        name="card_number"
                        placeholder={'0000 0000 0000 0000'}
                        format="#### #### #### ####"
                        mask="_"
                        errorClassName={styles.errorMessage}
                        className={styles.input}
                        icon={BankCardSVG}
                        iconHeight={20}
                        iconWidth={20}
                      />
                    </Form.Label>
                  </Col>
                  <Col xs={12} className="mt-3">
                    <Form.Label name="card_exp" title="Срок действия">
                      <Form.NumberInput
                        name="card_exp"
                        placeholder={t('verification.expirationDate')}
                        format="## / ##"
                        mask="_"
                        errorClassName={styles.errorMessage}
                        className={styles.input}
                      />
                    </Form.Label>
                  </Col>
                  <Col xs={12} className="mt-3">
                    <Form.Label name="card_exp" title={t('page.userProfile.phone')}>
                      <Form.NumberInput
                        isPhoneInput
                        name="user_phone"
                        icon={UserPhoneSVG}
                        placeholder={'__ ___ __ __'}
                        format="## ### ## ##"
                        mask="_"
                        errorClassName={styles.errorMessage}
                        className={styles.input}
                      />
                    </Form.Label>
                  </Col>
                </Row>
              </Form.Body>
            </div>
            <Form.Footer>
              <Button
                as="button"
                variant="primary-gradient"
                size="lg"
                type={'submit'}
                className={clsx(styles.addCardButton, 'mt-4')}
                disabled={methods.formState.isSubmitting}
              >
                Добавить картy <PlusSVG />
              </Button>
            </Form.Footer>
          </Form>
        </FormProvider>
      )}
      {!open && !isOnlyBalancePurchasing && (
        <Button
          as="button"
          onClick={handleOpen}
          variant="primary-gradient"
          size="lg"
          type={'submit'}
          className={styles.addCardButton}
          disabled={methods.formState.isSubmitting}
        >
          Добавить картy <PlusSVG />
        </Button>
      )}
      {isOnlyBalancePurchasing && <HasRequiredBalance />}
    </div>
  );
};
