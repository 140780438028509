import type { CreateSlice } from '@/zustand-store/store.types';

export type HeaderStore = {
  isSearchBarVisible: boolean;
  isNotificationVisible: boolean;
  isSidebarVisible: boolean;
  isCategoryVisible: boolean;
  toggleSearchBar: (isSearchBarVisible: boolean) => void;
  toggleCategoryPanel: (isCategoryVisible: boolean) => void;
  showSidebar: () => void;
  hideSidebar: () => void;
  showNotification: () => void;
  hideNotification: () => void;
};

export const createHeaderSlice: CreateSlice<HeaderStore> = set => ({
  isSearchBarVisible: false,
  isSidebarVisible: false,
  isCategoryVisible: false,
  isNotificationVisible: false,
  toggleSearchBar: isSearchBarVisible => set(state => ({ ...state, isSearchBarVisible })),
  toggleCategoryPanel: isCategoryVisible => set(state => ({ ...state, isCategoryVisible })),
  showSidebar: () => set(state => ({ ...state, isSidebarVisible: true })),
  hideSidebar: () => set(state => ({ ...state, isSidebarVisible: false })),
  showNotification: () => set(state => ({ ...state, isNotificationVisible: true })),
  hideNotification: () => set(state => ({ ...state, isNotificationVisible: false })),
});
