import type { SVGProps } from '@/types/common.types';

const HumoCardSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M21.5429 5.40149C21.5249 4.2431 21.166 3.08471 20.1608 2.43311C19.1736 1.79962 17.8634 1.81772 17.0916 2.77701C17.0377 2.84941 16.8941 2.99421 16.93 3.10281C16.9659 3.21141 17.2172 3.19331 17.3069 3.24761C17.4864 3.33811 17.5582 3.51911 17.5403 3.70011C17.5403 3.9173 17.5403 6.03499 17.5223 8.93097C14.9915 8.91287 12.4428 8.89477 9.91202 8.87667C9.85817 8.24318 9.84022 7.53728 9.89407 6.75899C9.92997 6.05309 10.0197 5.32909 10.1633 4.6232C10.2171 4.3155 10.2889 4.0078 10.3787 3.70011C10.4684 3.37431 10.7018 2.95801 10.6838 2.61411C10.6838 2.55981 10.6659 2.52361 10.6479 2.48741C10.5761 2.39691 10.4505 2.41501 10.3428 2.45121C9.69663 2.61411 9.06842 2.95801 8.56585 3.41051C8.42226 3.55531 8.00944 3.9354 7.63251 4.6051C6.66327 6.36079 6.57353 8.85857 6.57353 8.85857C6.51968 10.4695 6.46584 13.7455 8.17098 17.1483C8.17098 17.1483 9.37355 19.5375 11.0787 20.1167C11.4197 20.2253 11.5095 20.1529 11.5454 20.1167C11.671 19.9357 11.3838 19.6461 11.312 19.5194C11.1505 19.2841 11.0248 19.0126 10.9172 18.7592C10.6838 18.2162 10.5223 17.637 10.4146 17.0397C10.0556 15.2659 9.82227 13.4559 9.87612 11.646H17.5582C17.5582 13.9265 17.5582 16.2071 17.5582 18.4877C17.5582 18.9402 17.7377 19.3746 18.1505 19.5918C18.4018 19.7185 18.689 19.7728 18.9582 19.809C19.4429 19.8633 19.9454 19.8995 20.4301 19.809C20.7172 19.7547 20.9865 19.6461 21.2018 19.447C21.5429 19.1212 21.5429 18.7049 21.5429 18.2705C21.5429 14.8315 21.5788 11.4107 21.5788 7.97168C21.5608 7.10288 21.5608 6.25219 21.5429 5.40149Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M11.0367 20.9865C7.93153 20.0634 6.72896 16.7692 6.06485 13.8914C5.92126 13.2579 5.77767 12.6425 5.70588 12.009C5.45459 10.0723 5.47254 7.95462 6.0828 6.09033C5.61613 6.19893 5.40075 6.61523 5.11356 6.94102C4.10843 8.15372 4.01868 9.5836 3.98279 10.9954C3.87509 14.3077 5.05972 16.6607 5.32895 17.1674C5.93921 18.3077 6.58537 19.0498 6.78281 19.267C7.46486 20.0453 8.16487 20.5702 8.70333 20.9322C8.29051 20.8236 7.68025 20.6245 7.05204 20.2263C6.45973 19.8643 6.04691 19.448 5.75972 19.1403C5.36485 18.6335 5.07767 18.1629 4.89818 17.8552C4.41356 16.9864 3.78535 15.9186 3.48022 14.4887C3.39047 14.0905 3.33663 13.6742 3.17509 13.6561C2.94175 13.638 2.69047 14.4525 2.63662 14.6335C2.45713 15.2127 2.40329 15.7376 2.42124 16.0091C2.43918 17.095 3.24688 19.2127 5.16741 20.6426C5.16741 20.6426 6.8546 21.8915 9.74437 22.0001C9.87001 22.0001 10.57 21.982 11.0726 21.62C11.3059 21.4028 11.4854 21.1313 11.0367 20.9865Z"
        fill="white"
      />
    </svg>
  );
};

export default HumoCardSVG;
