import type { SVGProps } from '@/types/common.types';

const VideoSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <path
        d="M15.5 10L20.053 7.724C20.2054 7.64783 20.3748 7.61188 20.545 7.61954C20.7152 7.62721 20.8806 7.67824 21.0256 7.7678C21.1706 7.85736 21.2902 7.98247 21.3733 8.13127C21.4563 8.28006 21.4999 8.44761 21.5 8.618V15.382C21.4999 15.5524 21.4563 15.7199 21.3733 15.8687C21.2902 16.0175 21.1706 16.1426 21.0256 16.2322C20.8806 16.3218 20.7152 16.3728 20.545 16.3805C20.3748 16.3881 20.2054 16.3522 20.053 16.276L15.5 14V10Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 8C3.5 7.46957 3.71071 6.96086 4.08579 6.58579C4.46086 6.21071 4.96957 6 5.5 6H13.5C14.0304 6 14.5391 6.21071 14.9142 6.58579C15.2893 6.96086 15.5 7.46957 15.5 8V16C15.5 16.5304 15.2893 17.0391 14.9142 17.4142C14.5391 17.7893 14.0304 18 13.5 18H5.5C4.96957 18 4.46086 17.7893 4.08579 17.4142C3.71071 17.0391 3.5 16.5304 3.5 16V8Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6336_27269"
          x1="3.30164"
          y1="-2.57572"
          x2="-12.201"
          y2="34.6921"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFF4D" />
          <stop offset="1" stopColor="#F46600" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default VideoSVG;
