import type { SVGProps } from '@/types/common.types';

const PaymentErrorSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="202"
      height="202"
      viewBox="0 0 202 202"
      fill="none"
      {...props}
    >
      <rect
        x="27"
        y="11"
        width="148"
        height="148"
        rx="74"
        stroke="#FF4D4D"
        strokeOpacity="0.1"
        strokeWidth="2"
      />
      <g filter="url(#filter0_d_6264_28477)">
        <circle cx="101" cy="85" r="51" fill="#FF4D4D" />
        <circle cx="101" cy="85" r="50" stroke="url(#paint0_linear_6264_28477)" strokeWidth="2" />
      </g>
      <path
        d="M109.786 85.2495C109.649 85.112 109.649 84.8881 109.786 84.7506L126.576 67.9524C128.012 66.5162 128.012 64.1792 126.576 62.7429L123.248 59.4127C122.552 58.7166 121.627 58.3333 120.643 58.3333C119.659 58.3333 118.734 58.7166 118.038 59.4126L101.249 76.2109C101.164 76.2959 101.067 76.3138 101 76.3138C100.934 76.3138 100.837 76.296 100.752 76.2109L83.9623 59.4126C83.2666 58.7166 82.3416 58.3333 81.3575 58.3333C80.3735 58.3333 79.4486 58.7166 78.7529 59.4126L75.4244 62.7429C73.9887 64.1792 73.9887 66.5162 75.4244 67.9524L92.2143 84.7506C92.3518 84.8881 92.3518 85.112 92.2143 85.2495L75.4244 102.048C73.9888 103.484 73.9888 105.821 75.4244 107.257L78.7529 110.587C79.4486 111.283 80.3736 111.667 81.3575 111.667C82.3415 111.667 83.2666 111.283 83.9623 110.587L100.752 93.7892C100.837 93.7042 100.934 93.6864 101 93.6864C101.067 93.6864 101.163 93.7042 101.248 93.7893L118.038 110.587C118.734 111.284 119.659 111.667 120.643 111.667C121.627 111.667 122.552 111.284 123.248 110.587L126.576 107.257C128.012 105.821 128.012 103.484 126.576 102.048L109.786 85.2495Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_6264_28477"
          x="0"
          y="0"
          width="202"
          height="202"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="16" />
          <feGaussianBlur stdDeviation="25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.301961 0 0 0 0 0.301961 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6264_28477" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6264_28477"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_6264_28477"
          x1="101"
          y1="34"
          x2="101"
          y2="136"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PaymentErrorSVG;
