import { JSX } from 'react';

import { SubscriptionMainCardProps } from './SubscriptionMainCard';
import clsx from 'clsx';

import GameOutlineSVG from '@/icons/GameOutlineSVG';
import SmartTvOutlineSVG from '@/icons/SmartTvOutlineSVG';
import UzContentSVG from '@/icons/UzContentSVG';
import VideoSVG from '@/icons/VideoSVG';
import { ClassNameProps, SVGProps } from '@/types/common.types';

import { TransformedSubscription } from '@/api/types/user.types';

import styles from '@/components/SubscriptionCards/SubscriptionTagsList.module.scss';

// =================================================================

type SubscriptionTagsListProps = Pick<SubscriptionMainCardProps, 'type'> &
  ClassNameProps & {
    tags: TransformedSubscription['tags'];
  };

const tagsIcons: ((props: SVGProps) => JSX.Element)[] = [
  SmartTvOutlineSVG,
  VideoSVG,
  GameOutlineSVG,
  UzContentSVG,
];

// =================================================================

export const SubscriptionTagsList = (props: SubscriptionTagsListProps) => {
  const { tags, type, className } = props;
  return (
    <div className={clsx(styles.tagsList, className)}>
      {tags.map((tag, key) => {
        const Icon = tagsIcons[key];
        return (
          <div key={key} className={styles.tagElement}>
            <div className={clsx(styles.iconWrapper, styles[type])}>
              {Icon && <Icon width={20} height={20} />}
            </div>
            <div className={styles.tagsInfo}>
              <h5>{tag.split('+')[0]}+</h5>
              <p>{tag.split('+')[1]}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
