'use client';

import { PaymeForm } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/PaymeForm';
import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/PaymentSystems.module.scss';

export const PaymentSystems = () => {
  return (
    <div className={styles.paymentSystemWrapper}>
      <PaymeForm />
    </div>
  );
};
