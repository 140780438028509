'use client';

import { useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'next-intl';
import * as Yup from 'yup';

import UserPhoneSVG from '@/icons/UserPhoneSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';

import { Button, TextButton } from '@/components/Buttons';
import { Form } from '@/components/Form';

import { useNotification } from '@/contexts/NotificationContext';
import styles from '@/contexts/VerificationContext/VerificationModal/AuthVerification/AuthVerification.module.scss';

// =================================================================

interface PhoneFormProps {
  title: string;
}

type PhoneFormType = {
  phone: string;
  privacyAccepted: boolean;
};

// =================================================================

export const PhoneForm = (props: PhoneFormProps) => {
  const { title } = props;
  const t = useTranslations();
  const { notification } = useNotification();

  const { loginWithUsername, setPhone, acceptPolicy, setOtpStatus, phone, isAcceptedPolicy } =
    useVerification();

  const schema = useMemo(
    () =>
      Yup.object({
        phone: Yup.string()
          .trim()
          .required(t('formWarningMassage.requiredField'))
          .matches(/[0-9 ]{3}[0-9 ]{4}[0-9 ]{3}[0-9]{2}/, t('formWarningMassage.incorrectNumber')),
        privacyAccepted: Yup.boolean().required(),
      }),
    [t],
  );

  const methods = useForm<PhoneFormType>({
    defaultValues: {
      phone,
      privacyAccepted: Boolean(isAcceptedPolicy),
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const onSubmit: SubmitHandler<PhoneFormType> = async data => {
    try {
      const { phone, privacyAccepted } = data;
      const normalizedPhone = phone.replace(/\s/g, '');

      await UserApi.loginWithPhone(normalizedPhone);

      setPhone(phone);
      setOtpStatus();
      acceptPolicy(privacyAccepted);
    } catch (error) {
      notification('error', error?.message);
    }
  };

  const redirectPrivacyAccepted = () => {
    const phoneValue = methods.getValues('phone');
    setPhone(phoneValue);

    acceptPolicy(methods.getValues('privacyAccepted'));
  };

  return (
    <FormProvider {...methods}>
      <Form
        isSubmitting={methods.formState.isSubmitting}
        onSubmit={methods.handleSubmit(onSubmit)}
        className={styles.authForm}
      >
        <Form.Body className={styles.authFormBody}>
          <p className={styles.description}>{title}</p>
          <Form.NumberInput
            isPhoneInput
            name="phone"
            icon={UserPhoneSVG}
            placeholder={'__ ___ __ __'}
            format="## ### ## ##"
            mask="_"
            errorClassName={styles.errorMessage}
            className={styles.inputNumber}
            autoFocus
          />
          <Form.Checkbox
            name="privacyAccepted"
            label={
              <div className={styles.checkbox}>
                {t('verification.accept')}
                <TextButton
                  as="link"
                  href={'/privacy-policy?fromSignInModal=true'}
                  onClick={redirectPrivacyAccepted}
                  className={styles.link}
                >
                  {t('verification.termsOfUse')}
                </TextButton>
              </div>
            }
          />
        </Form.Body>
        <Form.Footer className={styles.formFooter}>
          <Button
            as="button"
            variant="primary"
            size="lg"
            type="submit"
            disabled={!methods.watch('privacyAccepted') || methods.formState.isSubmitting}
            className={styles.submitButton}
          >
            {t('common.continue')}
          </Button>
        </Form.Footer>
      </Form>
    </FormProvider>
  );
};
