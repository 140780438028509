import type { SVGProps } from '@/types/common.types';

const SmartTvOutlineSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M13.3333 2.5L10 5.83333L6.66667 2.5M2.5 7.5C2.5 7.05797 2.67559 6.63405 2.98816 6.32149C3.30072 6.00893 3.72464 5.83333 4.16667 5.83333H15.8333C16.2754 5.83333 16.6993 6.00893 17.0118 6.32149C17.3244 6.63405 17.5 7.05797 17.5 7.5V15C17.5 15.442 17.3244 15.866 17.0118 16.1785C16.6993 16.4911 16.2754 16.6667 15.8333 16.6667H4.16667C3.72464 16.6667 3.30072 16.4911 2.98816 16.1785C2.67559 15.866 2.5 15.442 2.5 15V7.5Z"
        stroke="url(#paint0_linear_6336_27263)"
        strokeWidth="1.09756"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6336_27263"
          x1="2.30321"
          y1="-3.77841"
          x2="-13.5051"
          y2="36.4599"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFF4D" />
          <stop offset="1" stopColor="#F46600" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SmartTvOutlineSVG;
