import { useState, useEffect, ChangeEvent } from 'react';

import PaymeSVG from '@/icons/PaymeSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { normalizePrise } from '@/helpers/transformations.helpers';

import { Button } from '@/components/Buttons';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/PaymeForm.module.scss';
import { useSubscriptionVerification } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts';

export const PaymeForm = () => {
  const { writeOff: price } = useSubscriptionVerification();

  const { userCredentials } = useVerification();
  const cineramaId = userCredentials?.cineramaId;
  const [amount, setAmount] = useState<string>('');

  useEffect(() => {
    setAmount(price ? price.toString() : '');
  }, [price]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  return (
    <div className={styles.paymeWrapper}>
      <form
        id="paycom_pay"
        method="POST"
        action="https://checkout.paycom.uz"
        className={styles.paymeForm}
      >
        <PaymeSVG />
        <input type="hidden" name="merchant" value="5fbf987a4602db1c688d9b76" autoComplete="off" />
        <input
          type="hidden"
          name="amount"
          value={(parseFloat(amount) * 100).toString()}
          autoComplete="off"
        />
        <input type="hidden" name="account[login]" value={cineramaId || ''} autoComplete="off" />
        <input type="hidden" name="callback" value={'https://cinerama.uz'} autoComplete="off" />
        <input
          type="hidden"
          name="description"
          value="Пополнение баланса cinerama.uz"
          autoComplete="off"
        />
        <input
          autoComplete="off"
          type="number"
          name={'payme'}
          disabled={Boolean(price)}
          placeholder="Введите сумму (мин. 1000)"
          min={1000}
          value={amount}
          onChange={handleChange}
          className={styles.amountInput}
          hidden
        />
      </form>

      <Button
        variant="primary-gradient"
        size="lg"
        as="button"
        className="mt-4"
        type="submit"
        form="paycom_pay"
        disabled={Number(price) < 1000}
      >
        Оплатить
        <span className={styles.bage}>{`${normalizePrise(price) || 0} UZS`}</span>
      </Button>
    </div>
  );
};
