'use client';

import { JSX } from 'react';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { EmptySVG } from '@/icons/EmptySVG';
import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import { Button } from '@/components/Buttons';
import styles from '@/components/ResultNotFound/ResultNotFound.module.scss';

// =================================================================

export interface ResultNotFoundProps extends ClassNameProps, Partial<ChildrenProps> {
  description?: string;
  title?: string;
  icon?: JSX.Element;
  submitTitle?: string;
  onSubmit?: () => void;
}

// =================================================================

export const ResultNotFound = (props: ResultNotFoundProps) => {
  const t = useTranslations('common');

  const {
    className,
    description = t('resultNotFoundDescription'),
    icon = <EmptySVG />,
    title = t('resultNotFoundTitle'),
    onSubmit,
    submitTitle,
    children,
  } = props;

  return (
    <div className={clsx(className, styles.placeholder)}>
      <div className={styles.placeholderWrapper}>
        <div className={styles.notFoundImage}>{icon}</div>

        <h2 className={styles.title}>{title || t('notFoundNothing')}</h2>
        {description && <p className={styles.description}>{description}</p>}
        {submitTitle && onSubmit && (
          <Button
            as="button"
            size="md"
            variant="primary-gradient"
            onClick={onSubmit}
            className={styles.notFoundButton}
          >
            {submitTitle}
          </Button>
        )}
        {children}
      </div>
    </div>
  );
};
